module.exports = {
  ENV: 'prod',
  APP_NAME: 'veolia',
  END_POINT: 'https://ssm.services.apihf.com',
  API_KEY: '96K4mzfiSr6zDfqfvAOWS3yP3msLsHrR5pLjhYUy',
  BRAND_KEY: 'b2b26328-27e3-4fd0-857d-12989a31901e',
  CLIENT_ID: '2hcnhauq2qob6f67q6ikcb7of1',
  USER_POOL_ID: 'eu-west-1_HIn9p5IYR',
  HEADER_MESSAGE_SIZE: 41,
  AGENTS_GROUP: 'VEOLIA_AGENTS',
}